// 管理者向けの団体の取得

import api from '../api'

const path = '/admin/organizations'

export default () => {
  return api({
    url: path,
    auth: true,
  })
}
