// 団体の情報編集ダイアログ

<template lang="pug">
el-dialog.modify-organization-dialog(
  title='団体の作成と編集',
  width='80%',
  :close-on-click-modal='false',
  :visible='visible',
  @update:visible='$emit("update:visible", $event)',
  @open='open'
)

  article

    el-form(
      :model='form',
      label-position='top',
      :rules='rules',
      ref='form'
    )

      el-form-item(
        label='園番号',
        prop='membershipNumber'
      )
        el-input.short-input(v-model.number='form.membershipNumber')

      el-row.row(:gutter='20')
        el-col(:span='12')
          el-form-item(
            label='地区',
            prop='regionId'
          )
            el-select(
              placeholder='地区を選択',
              v-model='form.regionId'
            )
              el-option(
                label='未選択',
                :value='null'
              )
              el-option(
                v-for='item in regions',
                :key='item.id',
                :label='item.name',
                :value='item.id'
              )
        el-col(:span='12')
          el-form-item(
            label='施設類型',
            prop='facilityTypeId'
          )
            el-select(
              placeholder='施設類型を選択',
              v-model='form.facilityTypeId'
            )
              el-option(
                label='未選択',
                :value='null'
              )
              el-option(
                v-for='item in facilityTypes',
                :key='item.id',
                :label='item.description',
                :value='item.id'
              )

      el-row.row(:gutter='20')
        el-col(:span='12')
          el-form-item(
            label='法人名'
            prop='corporateName'
          )
            el-input(v-model='form.corporateName')
        el-col(:span='12')
          el-form-item(
            label='法人名 (ひらがな)'
            prop='corporateNameKana'
          )
            el-input(v-model='form.corporateNameKana')

      el-row.row(:gutter='20')
        el-col(:span='12')
          el-form-item(
            label='園名'
            prop='name'
          )
            el-input(v-model='form.name')
        el-col(:span='12')
          el-form-item(
            label='園名 (ひらがな)'
            prop='nameKana'
          )
            el-input(v-model='form.nameKana')

      el-row.row(:gutter='20')
        el-col(:span='12')
          el-form-item(
            label='TEL (ハイフン無し)'
            prop='tel'
          )
            el-input(v-model='form.tel')
        el-col(:span='12')
          el-form-item(
            label='FAX (ハイフン無し)'
            prop='fax'
          )
            el-input(v-model='form.fax')

      el-form-item(
        label='郵便番号 (ハイフン無し)',
        prop='zip'
      )
        el-input.short-input(v-model='form.zip')

      el-form-item(
        label='住所',
        prop='address'
      )
        el-input(v-model='form.address')

      el-form-item(
        label='メールアドレス (複数入力時は改行またはコンマで区切る)',
        prop='emails'
      )
        el-input(
          type='textarea',
          v-model='emailsStr',
          autosize
        )
        el-checkbox(
          v-model='form.canContactFromWeb'
        ) 協会ホームページからの問合せを許可
        el-checkbox(
          v-model='form.canContact'
        ) 協会アプリからの問合せを許可

      el-form-item(
        label='問合せ用メールアドレス (複数入力時は改行またはコンマで区切る)',
        prop='emailsForContact'
      )
        el-input(
          type='textarea',
          v-model='emailsForContactStr',
          autosize
        )
        p.emails-for-contact-note
          | ※協会のホームページからの問合せ内容を送るメールアドレスを指定します。
          | 空の場合は、通常のメールアドレスが使用されます。

      el-row.row(:gutter='20')
        el-col(:span='12')
          el-form-item(
            label='緯度',
            prop='lat'
          )
            el-input(
              type='tel',
              :value='form.lat',
              @input='form.lat = $event',
              @blur='form.lat = parseFloat($event.target.value) || 0'
            )
        el-col(:span='12')
          el-form-item(
            label='経度',
            prop='lng'
          )
            el-input(
              type='tel',
              :value='form.lng',
              @input='form.lng = $event',
              @blur='form.lng = parseFloat($event.target.value) || 0'
            )

      el-form-item(
        label='ホームページ',
        prop='url'
      )
        el-input(v-model='form.url')

      el-form-item(
        label='タグ'
      )
        el-checkbox-group(
          v-model='form.tags'
        )
          el-checkbox(
            v-for='item in allTags'
            :key='item.id',
            :label='item.id'
          ) {{ item.description }}

    footer
      el-button(
        type='primary',
        @click='save'
      ) 登録
      el-button(
        @click='close(false)'
      ) キャンセル

</template>

<script>
import getRegionListApi from '@/api/get-region-list'
import getOrganizationFacilityTypes from '@/api/get-organization-facility-types'
import getOrganizationTagListApi from '@/api/get-organization-tag-list'
import createOrganizationApi from '@/api/admin/create-organization'
import getOrganizationApi from '@/api/admin/get-organization'
import updateOrganizationApi from '@/api/admin/update-organization'

export default {
  name: 'ModifyOrganizationDialog',

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    organizationId: {
      type: Number,
    },
  },

  data() {
    return {
      form: {
        membershipNumber: null,
        regionId: null,
        facilityTypeId: null,
        corporateName: null,
        corporateNameKana: null,
        name: null,
        nameKana: null,
        tel: null,
        fax: null,
        zip: null,
        address: null,
        emails: [],
        // お問い合わせ送信専用のメールアドレス
        emailsForContact: [],
        canContact: false,
        canContactFromWeb: false,
        lat: null,
        lng: null,
        url: null,
        tags: [],
      },
      rules: {
        membershipNumber: [
          { required: true, message: '必須項目です。' },
          { pattern: /^\d+$/, message: '数字で入力してください。' },
        ],
        corporateNameKana: [
          { pattern: /^[ぁ-ゞー～\s]+$/, message: 'ひらがなで入力してください。' },
        ],
        name: [{ required: true, message: '必須項目です。' }],
        nameKana: [
          { required: true, message: '必須項目です。' },
          { pattern: /^[ぁ-ゞー～\s]+$/, message: 'ひらがなで入力してください。' },
        ],
        tel: [{ pattern: /^\d{10,11}$/, message: '入力が正しくありません。' }],
        fax: [{ pattern: /^\d{10,11}$/, message: '入力が正しくありません。' }],
        zip: [{ pattern: /^\d{7}$/, message: '入力が正しくありません。' }],
        emails: [
          {
            type: 'array',
            defaultField: { type: 'email', message: 'メールアドレスを入力してください。' },
          },
        ],
        emailsForContact: [
          {
            type: 'array',
            defaultField: { type: 'email', message: 'メールアドレスを入力してください。' },
          },
        ],
        url: [{ type: 'url', message: '正しい形式で入力してください。' }],
      },
      emailsStr: null,
      emailsForContactStr: null,
      regions: [],
      // 施設類型
      facilityTypes: [],
      allTags: [],
    }
  },

  async created() {
    const response = await getRegionListApi()
    if (!response.ok) return
    this.regions = response.payload.items
    const facilityTypesResponse = await getOrganizationFacilityTypes()
    if (!facilityTypesResponse.ok) return
    this.facilityTypes = facilityTypesResponse.payload.items
    const responseTags = await getOrganizationTagListApi()
    if (!responseTags.ok) return
    this.allTags = responseTags.payload.items
  },

  methods: {
    async open() {
      if (this.$refs.form) this.$refs.form.resetFields()
      this.emailsStr = null
      this.emailsForContactStr = null
      // クリアしないと前の値を引き継いでしまう
      // resetFieldsは配列はクリアしない
      this.form.tags = []
      if (this.organizationId) {
        const response = await getOrganizationApi(this.organizationId)
        if (!response.ok) return
        const payload = response.payload
        this.form.membershipNumber = payload.membershipNumber
        this.form.regionId = payload.regionId
        this.form.facilityTypeId = payload.facilityTypeId
        this.form.corporateName = payload.corporateName
        this.form.corporateNameKana = payload.corporateNameKana
        this.form.name = payload.name
        this.form.nameKana = payload.nameKana
        this.form.tel = payload.tel
        this.form.fax = payload.fax
        this.form.zip = payload.zip
        this.form.address = payload.address
        this.form.lat = payload.lat
        this.form.lng = payload.lng
        this.form.url = payload.url
        this.form.canContact = payload.canContact
        this.form.canContactFromWeb = payload.canContactFromWeb
        this.form.tags = (payload.tags || []).map((item) => item.id)
        if (payload.emails && payload.emails.length) {
          this.emailsStr = payload.emails.join('\n')
        }
        if (payload.emailsForContact && payload.emailsForContact.length) {
          this.emailsForContactStr = payload.emailsForContact.join('\n')
        }
      }
    },
    close(success) {
      if (success) this.$emit('ok')
      this.$emit('update:visible', false)
    },
    async save() {
      // バリデーション
      try {
        await this.$refs.form.validate()
      } catch (e) {
        await this.$alert('入力に誤りがあります。', 'エラー', {
          type: 'warning',
        })
        return
      }
      let response
      if (this.organizationId) {
        response = await updateOrganizationApi(this.organizationId, this.form)
      } else {
        response = await createOrganizationApi(this.form)
      }
      if (!response.ok) {
        this.$alert('保存に失敗しました。', 'エラー', {
          type: 'error',
        })
        return
      }
      // ダイアログを閉じる
      this.close(true)
    },
  },

  watch: {
    emailsStr(value) {
      if (!value) {
        this.form.emails = []
        return
      }
      const _value = value.trim()
      this.form.emails = _value.split(/[\n,]/).filter((item) => item)
    },
    emailsForContactStr(value) {
      if (!value) {
        this.form.emailsForContact = []
        return
      }
      const _value = value.trim()
      this.form.emailsForContact = _value.split(/[\n,]/).filter((item) => item)
    },
  },
}
</script>

<style lang="sass">
.modify-organization-dialog
  article
    .el-form-item__label
      padding-bottom: 0.1rem
</style>

<style lang="sass" scoped>
.modify-organization-dialog

  article
    margin-top: -20px

    .short-input
      max-width: 15rem

    .emails-for-contact-note
      line-height: 1.5
      font-size: 0.85rem
      color: #218621
</style>
