// ユーザのパスワードの更新

import api from '../api'

const path = '/admin/users/{id}/password'

export default (userId, password) => {
  const url = path.replace('{id}', userId)
  return api({
    method: 'put',
    url,
    params: {
      password,
    },
    auth: true,
  })
}
