// 特定の団体のメンバーのリストを表示する

<template lang="pug">
el-dialog.organization-members-dialog(
  title='メンバー一覧',
  :visible='visible',
  @update:visible='$emit("update:visible", $event)',
  @open='open'
)

  article

    el-table(
      :data='members'
    )

      el-table-column(
        label='種類'
      )
        template(slot-scope='scope')
          span {{ scope.row.roleId === 'org' ? 'メイン' : scope.row.roleName }}

      el-table-column(
        label='ログインID',
        prop='loginName'
      )

      el-table-column(
        align='right'
      )
        template(slot-scope='scope')

          el-button(
            size='mini',
            @click='changeLoginName(scope.row.id)'
          ) ID変更
          el-button(
            size='mini',
            @click='changePassword(scope.row.id)'
          ) パスワード変更
</template>

<script>
import getOrganizationMemberListApi from '@/api/admin/get-organization-member-list'
import updateUserLoginNameApi from '@/api/admin/update-user-login-name'
import updateUserPasswordApi from '@/api/admin/update-user-password'

export default {
  name: 'OrganizationMembersDialog',

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    organizationId: {
      type: Number,
    },
  },

  data() {
    return {
      members: [],
    }
  },

  methods: {
    open() {
      this.init()
    },

    async init() {
      this.members = []
      const response = await getOrganizationMemberListApi(this.organizationId)
      if (!response.ok) return
      this.members = response.payload.items
    },

    async changeLoginName(userId) {
      let loginName
      try {
        const result = await this.$prompt(
          '新しいログインIDを入力してください。',
          'ログインIDの変更',
          {
            confirmButtonText: '変更',
            cancelButtonText: 'キャンセル',
            inputPattern: /^[A-Za-z0-9-_]+$/,
            inputErrorMessage: '半角英数字で入力してください。',
          }
        )
        loginName = result.value
      } catch (e) {
        return
      }
      const response = await updateUserLoginNameApi(userId, loginName)
      if (!response.ok) {
        this.$alert('変更できませんでした。', 'エラー', {
          type: 'error',
        })
      } else {
        this.$message({
          message: '変更しました。',
          type: 'success',
        })
        this.init()
      }
    },

    async changePassword(userId) {
      let password
      try {
        const result = await this.$prompt(
          '新しいパスワードを入力してください。',
          'パスワードの変更',
          {
            confirmButtonText: '変更',
            cancelButtonText: 'キャンセル',
            inputPattern: /^[!-~]{8,}$/,
            inputErrorMessage: '半角8文字以上で入力してください。',
          }
        )
        password = result.value
      } catch (e) {
        return
      }
      const response = await updateUserPasswordApi(userId, password)
      if (!response.ok) {
        this.$alert('変更できませんでした。', 'エラー', {
          type: 'error',
        })
      } else {
        this.$message({
          message: '変更しました。',
          type: 'success',
        })
        this.init()
      }
    },
  },
}
</script>
