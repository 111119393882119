// 団体の詳細の取得

import api from '../api'

const path = '/admin/organizations/{id}'

export default (organizationId) => {
  const url = path.replace('{id}', organizationId)
  return api({
    url,
    auth: true,
  })
}
