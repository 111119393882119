// 特定の団体のメンバーのリストの取得

import api from '../api'

const path = '/admin/organizations/{id}/members'

export default (organizationId) => {
  const url = path.replace('{id}', organizationId)
  return api({
    url,
    auth: true,
  })
}
