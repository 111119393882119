// ユーザのログイン名の更新

import api from '../api'

const path = '/admin/users/{id}/login-name'

export default (userId, loginName) => {
  const url = path.replace('{id}', userId)
  return api({
    method: 'put',
    url,
    params: {
      loginName,
    },
    auth: true,
  })
}
