// 団体の削除

import api from '../api'

const path = '/admin/organizations/{id}'

export default (organizationId) => {
  const url = path.replace('{id}', organizationId)
  return api({
    url,
    method: 'delete',
    auth: true,
  })
}
