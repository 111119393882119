// 団体の管理

<template lang="pug">
el-card.admin-organizations

  header
    .go-back(style='margin-bottom: 0.5rem')
      el-button(type='text', icon='el-icon-back', @click='$router.go(-1)') 戻る

    el-button(
      type='primary',
      icon='el-icon-circle-plus',
      @click='openModifyOrganizationDialog'
    ) 園を追加

    .indicator
      span 全
      b {{ organizations.length }}
      span 件

  el-table.organization-list(:data='organizations')

    el-table-column(
      width='100',
      label='園番号',
      prop='membershipNumber',
      align='center'
    )

    el-table-column(
      width='100',
      label='地区',
      prop='regionName'
    )

    el-table-column(
      label='園名'
    )
      template(slot-scope='scope')
        .name {{ scope.row.corporateName ? `${scope.row.corporateName} ${scope.row.name}` : scope.row.name }}
        .url(v-if='scope.row.url')
          a(
            :href='scope.row.url',
            target='_blank',
            rel='noopener'
          ) {{ scope.row.url }}
        .facility-type {{ scope.row.facilityTypeName ? `(${scope.row.facilityTypeName})` : '' }}

    el-table-column(
      label='TEL/FAX'
    )
      template(slot-scope='scope')
        .tel TEL: {{ scope.row.tel || '---' }}
        .fax FAX: {{ scope.row.fax || '---' }}

    el-table-column(
      label='メール'
    )
      template(slot-scope='scope')
        .mail(v-for='mail in scope.row.emails || []') {{ mail }}
        .mail-for-contact(v-for='mail in scope.row.emailsForContact || []') [問合せ用] {{ mail }}
        .is-contactable
          | 問い合わせ(ウェブ):
          | {{ scope.row.canContactFromWeb ? '許可する' : '許可しない' }}
          br
          | 問い合わせ(アプリ):
          | {{ scope.row.canContact ? '許可する' : '許可しない' }}


    el-table-column(
      width='80',
      label='タグ',
      align='center'
    )
      template(slot-scope='scope')
        .tag(v-for='tag in scope.row.tags || []') [{{ tag.name }}]

    el-table-column(
      align='right'
    )
      template(slot-scope='scope')
        el-button.btn(
          size='mini',
          type='success',
          plain,
          @click='modifyOrganization(scope.row.id)'
        ) 編集
        el-button.btn(
          size='mini',
          type='warning',
          plain,
          @click='openOrganizationMembersDialog(scope.row.id)'
        ) メンバー
        el-button.btn(
          size='mini',
          type='danger',
          plain,
          @click='removeOrganization(scope.row.id)'
        ) 削除

  modify-organization-dialog(
    :visible.sync='modifyOrganizationDialogVisible',
    :organization-id='currentOrganizationId',
    @ok='updateOrganizations'
  )
  organization-members-dialog(
    :visible.sync='organizationMembersDialogVisible',
    :organization-id='currentOrganizationId'
  )
</template>

<script>
import getOrganizationListApi from '@/api/admin/get-organization-list'
import removeOrganizationApi from '@/api/admin/remove-organization'

import ModifyOrganizationDialog from '@/dialogs/modify-organization-dialog'
import OrganizationMembersDialog from '@/dialogs/organization-members-dialog'

export default {
  name: 'AdminOrganizations',

  components: {
    ModifyOrganizationDialog,
    OrganizationMembersDialog,
  },

  data() {
    return {
      organizations: [],
      modifyOrganizationDialogVisible: false,
      organizationMembersDialogVisible: false,
      currentOrganizationId: null,
    }
  },

  created() {
    this.init()
  },

  methods: {
    async init() {
      const response = await getOrganizationListApi()
      if (!response.ok) return
      this.organizations = response.payload.items
    },

    openModifyOrganizationDialog() {
      this.currentOrganizationId = null
      this.modifyOrganizationDialogVisible = true
    },

    modifyOrganization(organizationId) {
      this.currentOrganizationId = organizationId
      this.modifyOrganizationDialogVisible = true
    },

    openOrganizationMembersDialog(organizationId) {
      this.currentOrganizationId = organizationId
      this.organizationMembersDialogVisible = true
    },

    async removeOrganization(organizationId) {
      try {
        await this.$confirm('本当に削除してもよろしいですか？', '確認', {
          confirmButtonText: '削除',
          cancelButtonText: 'キャンセル',
        })
      } catch (e) {
        return
      }
      const response = await removeOrganizationApi(organizationId)
      if (!response.ok) {
        this.$alert('削除できませんでした。', 'エラー', {
          type: 'error',
        })
      } else {
        this.$message({
          message: '削除しました。',
          type: 'success',
        })
        await this.init()
      }
    },

    updateOrganizations() {
      this.$message({
        message: '会員園の情報を保存しました。',
        type: 'success',
      })
      this.init()
    },
  },
}
</script>

<style lang="sass" scoped>
.admin-organizations
  .indicator
    float: right
    b
      font-size: 1.2rem
    span
      font-size: 0.9rem
      margin: 0.2rem
  .organization-list
    .is-contactable
      font-size: 0.85rem
    .tag
      font-size: 0.85rem
      line-height: 1.3
    .btn
      margin: 0.1rem
</style>
