// 団体の情報の更新 (管理者向け)

import api from '../api'

const path = '/admin/organizations/{id}'

export default (
  organizationId,
  {
    membershipNumber,
    regionId,
    facilityTypeId,
    corporateName,
    corporateNameKana,
    name,
    nameKana,
    tel,
    fax,
    zip,
    address,
    emails,
    emailsForContact,
    lat,
    lng,
    url,
    canContact,
    canContactFromWeb,
    tags,
  }
) => {
  return api({
    method: 'put',
    url: path.replace('{id}', organizationId),
    params: {
      membershipNumber,
      regionId,
      facilityTypeId,
      corporateName,
      corporateNameKana,
      name,
      nameKana,
      tel,
      fax,
      zip,
      address,
      emails: emails && emails.length ? emails : null,
      emailsForContact: emailsForContact && emailsForContact.length ? emailsForContact : null,
      lat: lat || null,
      lng: lng || null,
      url,
      canContact,
      canContactFromWeb,
      tags,
    },
    auth: true,
  })
}
