// タグの一覧を取得

import api from './api'

const path = '/organization-tags'

export default () => {
  return api({
    url: path,
    auth: true,
  })
}
