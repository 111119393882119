// 団体の作成

import api from '../api'

const path = '/admin/organizations'

export default ({
  membershipNumber,
  regionId,
  facilityTypeId,
  corporateName,
  corporateNameKana,
  name,
  nameKana,
  tel,
  fax,
  zip,
  address,
  emails,
  emailsForContact,
  lat,
  lng,
  url,
  canContact,
  canContactFromWeb,
  tags,
}) => {
  return api({
    method: 'post',
    url: path,
    params: {
      membershipNumber,
      regionId,
      facilityTypeId,
      corporateName,
      corporateNameKana,
      name,
      nameKana,
      tel,
      fax,
      zip,
      address,
      emails: emails && emails.length ? emails : null,
      emailsForContact: emailsForContact && emailsForContact.length ? emailsForContact : null,
      lat: lat || null,
      lng: lng || null,
      url,
      canContact,
      canContactFromWeb,
      tags,
    },
    auth: true,
  })
}
