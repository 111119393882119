// 地区の一覧の取得

import api from './api'

const path = '/regions'

export default () => {
  return api({
    url: path,
  })
}
